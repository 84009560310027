import { TableSortLabel, Typography, styled, tableSortLabelClasses } from "@mui/material";
import { HeaderProps } from "react-table";
import Tooltip from "../Tooltip";
import InfoIcon from "../icons/InfoIcon";

const TableHeaderInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
}));

const TableHeaderSortLabel = styled(TableSortLabel)(() => ({
    width: "100%",
    alignItems: "flex-start",
    [`&:hover .${tableSortLabelClasses.icon}`]: {
        maxWidth: "inherit", // show icon on hover (unset is not supported by IE)
    },
    [`& .${tableSortLabelClasses.icon}`]: {
        maxWidth: 0, // hide icon by default
    },
    [`&.${tableSortLabelClasses.active} .${tableSortLabelClasses.icon}`]: {
        maxWidth: "inherit", // show icon when active (unset is not supported by IE)
    },
}));

const TableHeaderCell = <D extends object = Record<string, unknown>>({ column }: HeaderProps<D>) => {
    const { label, id, headerTooltip } = column;
    const content = String(label ?? id);
    const contentWithTooltip = (
        <Tooltip title={headerTooltip ?? content} placement="right" onlyOverflowing={headerTooltip == null}>
            <Typography variant="subtitle2" noWrap>
                {content}
                {headerTooltip != null && <TableHeaderInfoIcon />}
            </Typography>
        </Tooltip>
    );

    if (!column.canSort) {
        return contentWithTooltip;
    }

    // only pass click-handler, other props (styling, tooltips) are provided by MUI
    const { onClick } = column.getSortByToggleProps();
    return (
        <TableHeaderSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"} onClick={onClick}>
            {contentWithTooltip}
        </TableHeaderSortLabel>
    );
};

export default TableHeaderCell;

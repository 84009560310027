import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Checkbox, Grid, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, Typography } from "@mui/material";
import classNames from "classnames";
import { TFunction } from "i18next";
import { isEqual, times } from "lodash";
import { Suspense, useEffect, useMemo } from "react";
import {
    CellProps,
    Column,
    FilterTypes,
    HeaderProps,
    IdType,
    Row,
    SortByFn,
    SortingRule,
    TableKeyedProps,
    TableOptions,
    useFlexLayout,
    useGlobalFilter,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import { useAlphanumericStringSort } from "../../hooks/useAlphanumericStringSort";
import usePrevious from "../../hooks/usePrevious";
import { compareUsersByRealName } from "../../lib/sort";
import BaseTableBodyRow from "./BaseTableBodyRow";
import BaseTableRow from "./BaseTableRow";
import Pagination from "./Pagination";
import TableResizer from "./TableResizer";
import TableRowSkeleton from "./TableRowSkeleton";

const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100, 200, 500];

const DEFAULT_SORT_TYPES: Record<string, SortByFn<any>> = {
    user: (a: Row<any>, b: Row<any>, columnId: any) => compareUsersByRealName(a.values[columnId], b.values[columnId]),
};

function resolveNumberOfItems<D extends object = Record<string, unknown>>(data?: readonly D[] | null, override?: number) {
    if (override !== undefined) {
        return override;
    }
    return Array.isArray(data) ? data.length : 0;
}

/**
 * When column resizing is disabled on a column (either for that column or globally on the table), the table will disable the flex-growing of that column.
 * This is useful in some situations, e.g. when a checkbox is placed at the start that should not grow.
 *
 * This behaviour also applies to fully non-resizable tables, e.g. in the report section. Altough the columns are not resizable, the table should still use
 * all of the available space. So applying this fix re-adds the flex-grow attribute, when resizing is disabled globally on the table, but still allows having specific
 * non-growing columns.
 *
 * @param {TableKeyedProps} props
 * @param {*} column the table column object. Needed for totalFlexWidth (which is not part of the typings)
 * @param {boolean} [disableResizing]
 * @returns
 */
export function fixColumnFlexing(props: TableKeyedProps, column: any, disableResizing?: boolean) {
    return {
        ...props,
        style: {
            ...props.style,
            flexGrow: column.totalFlexWidth == null || disableResizing ? 1 : column.totalFlexWidth,
        },
    };
}

/* Filter types to make user column searchable using global filter. */
const filterTypes: FilterTypes<any> = {
    globalFilter: (rows, columnIds, filterValue) => {
        return rows.filter((r) =>
            columnIds.some((cId) => {
                const currentColumnValue = r.values[cId];
                const lowerFilterValue = filterValue.toLowerCase();

                if (currentColumnValue === null || currentColumnValue === undefined) {
                    return false;
                }

                if (typeof currentColumnValue === "object" && "displayname" in currentColumnValue) {
                    return currentColumnValue.displayname?.toLowerCase().includes(lowerFilterValue);
                } else if (typeof currentColumnValue === "number") {
                    return currentColumnValue.toString().toLowerCase().includes(lowerFilterValue);
                } else if (typeof currentColumnValue === "string") {
                    return currentColumnValue.toLowerCase().includes(lowerFilterValue);
                }

                // fallback
                return false;
            }),
        );
    },
};

const BaseTableRoot = styled(Grid)({
    height: "100%",
});

const fullHeightClass = "VdBaseTable-fullHeight";

const BaseTableContainer = styled(TableContainer)({
    flexGrow: 1,
    minHeight: 0,
    [`&.${fullHeightClass}`]: {
        height: 0,
    },
});

const StyledTable = styled(Table)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // Don't know why, but this fixes columns being cut off on right
    // Together with minWidth 100% on table(Head|Body|Footer)
    alignItems: "baseline",
});

const BaseTableHead = styled(TableHead)(({ theme }) => ({
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.divider}`,
    minWidth: "100%",
}));

const BaseTableBody = styled(TableBody)({
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    // scroll table body, so footer & header will be sticky
    overflowY: "auto",
    overflowX: "hidden", // prevent horizontal scroll here, will be handled by "table"
    msOverflowStyle: "-ms-autohiding-scrollbar",
    minWidth: "100%",
});

const BaseTableFooter = styled(TableFooter)(({ theme }) => ({
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    borderTop: `1px solid ${theme.palette.divider}`,
    minWidth: "100%",
}));

const BaseTableCheckbox = styled(Checkbox)(({ theme }) => ({
    padding: theme.spacing(0.5),
}));

function SelectionHeaderComponent<D extends object = Record<string, unknown>>({ getToggleAllPageRowsSelectedProps }: HeaderProps<D>) {
    return <BaseTableCheckbox {...getToggleAllPageRowsSelectedProps()} />;
}

function SelectionCellComponent<D extends object = Record<string, unknown>>({ row }: CellProps<D>) {
    return <BaseTableCheckbox {...row.getToggleRowSelectedProps()} />;
}

const PaginationContainer = styled(Grid)(({ theme }) => ({
    borderTop: `2px solid ${theme.palette.divider}`,
}));

const PaginationHint = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    lineHeight: "normal",
    marginLeft: theme.spacing(2),
}));

const BaseTableRowSkeleton = styled(TableRowSkeleton)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexGrow: 0,
    minHeight: theme.spacing(5),
    borderBottom: `${theme.palette.divider} 1px solid`,
}));

const denseClass = "VdBaseTable-dense";

const BaseTableHeadCell = styled(TableCell)(({ theme }) => ({
    display: "block", // override default display: table-cell, do not use flex here so children are not flex-items
    overflow: "hidden", // enable ellipsis on table cells
    borderBottom: "none", // border is moved from cell to row, so that vertical centering of cells is possible
    borderRight: `${theme.palette.divider} 1px solid`,
    "&:last-child": {
        borderRight: "none",
    },
    padding: theme.spacing(2),
    [`&.${denseClass}`]: {
        padding: theme.spacing(1, 2),
    },
}));

const BaseTableFooterCell = styled(TableCell)(({ theme }) => ({
    display: "block", // override default display: table-cell, do not use flex here so children are not flex-items
    overflow: "hidden", // enable ellipsis on table cells
    borderBottom: "none", // border is moved from cell to row, so that vertical centering of cells is possible
    padding: theme.spacing(2),
    [`&.${denseClass}`]: {
        padding: theme.spacing(1, 2),
    },
}));

const BaseTableRowSkeletonBars = () => (
    <>
        {times(10, (i) => (
            <BaseTableRowSkeleton key={`skeleton_${i}`} />
        ))}
    </>
);

export interface IBaseTableProps<D extends object = Record<string, unknown>> extends TableOptions<D> {
    /** Text that will be displayed when the data set is available, but empty. */
    noDataText: string;

    /** Total number of rows/items that is displayed in the pagination section. */
    numOfItems?: number;

    /** Initially selected pagesize. Changing this value after initialization will not update the pagesize. */
    defaultPageSize?: number;

    /** Available page sizes that appear in the selectbox. */
    pageSizeOptions?: number[];

    /** Descriptive name of the items that are displayed by the table. Will be displayed in the pagination section together with numOfItems. */
    itemName?: string;

    /** Translate function that will be used to localize some of the table's strings. */
    translate: TFunction;

    /** Disable pagination functionality and do not display pagination section at the bottom. */
    disablePagination?: boolean;

    /** Predicate to check if a row should be displayed as selected state. */
    isRowSelected?: (value: D) => boolean;

    /**
     * Initial sorting value. Changing this value after initialization will not update the pagesize.
     * Needs to be memoized, although it only affect initialization, but can still trigger unneeded re-renders.
     */
    defaultSortBy?: SortingRule<D>[];

    /** Initially selected page size. Changing this value after initialization will not update the pagesize. */
    defaultPageIndex?: number;

    /** Initially selected rows. This property is linked with onSelectionChanged callback. */
    defaultSelectedIds?: Record<IdType<D>, boolean>;

    /** Selection column is only added if this callback is set! */
    onSelectionChanged?: (selectedItems: Row<D>[]) => void;

    /** Changes to the sorting value will trigger this callback. */
    onSortByChanged?: (newSortBy: SortingRule<D>[]) => void;

    /** Changes to the order of rows will trigger this callback with an array of the items in the newly sorted order. */
    onOrderChanged?: (newOrder: D[]) => void;

    /** When the selected page or the pageSize changes, this callback is triggered. */
    onPaginationChanged?: (pageIndex: number, pageSize: number) => void;

    /**
     * When a column is resized, this callback is triggered. It will only get triggered, when the resizing has finished.
     * The parameter contains the widths of all columns, even those that might currently not be visible.
     */
    onColumnsResized?: (newWidths: Record<string, number>) => void;

    /** class name(s) to append to the root dom element. */
    className?: string;

    /**
     * Should be set to true, if the table should grow to cover all existing vertical space.
     * In card contexts, where the table should claim as much space as it needs, this should be unset/false.
     */
    fullHeight?: boolean;

    /** Enable hover hightlighting on table rows. */
    rowHover?: boolean;

    /** Whether to use denser spacing for the table header and footer, e.g. for use in widgets. */
    isDense?: boolean;

    /** Use the dense version of the pagination, that hides some of page navigation buttons. */
    densePagination?: boolean;

    /** Add a hint next to the pagination controls. */
    paginationHint?: string;

    /** This can be used to control the tables internal pageIndex state. */
    pageIndex?: number;

    /** Whether to show the loading state with skeletons. */
    isFetching?: boolean;
}

const BaseTable = <D extends object = Record<string, unknown>>({
    className,
    columns,
    data,
    numOfItems: numOfItemsProps,
    pageIndex: pageIndexProps,
    noDataText,
    translate,
    itemName,
    disablePagination,
    isRowSelected,
    defaultSortBy,
    onOrderChanged,
    onSortByChanged,
    onPaginationChanged,
    onColumnsResized,
    defaultPageIndex,
    fullHeight,
    sortTypes = DEFAULT_SORT_TYPES,
    defaultPageSize = 50,
    pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    disableMultiSort = true,
    disableSortRemove = true,
    rowHover,
    densePagination,
    paginationHint,
    globalFilter,
    defaultSelectedIds,
    onSelectionChanged,
    isFetching,
    isDense,
    ...tableOptions
}: IBaseTableProps<D>) => {
    const { compare } = useAlphanumericStringSort();
    const processedColumns = useMemo<Column<D>[]>(
        () =>
            columns.map((column) => {
                // set "default" sortType, if none is provided
                if (!column.sortType) {
                    return {
                        ...column,
                        sortType: (a: Row<any>, b: Row<any>, columnId: any) => compare(a.values[columnId], b.values[columnId]),
                    };
                }
                return column;
            }),
        [columns, compare],
    );

    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        prepareRow,
        footerGroups,
        state,
        gotoPage,
        setPageSize,
        page,
        setGlobalFilter,
        globalFilteredRows,
        selectedFlatRows,
    } = useTable(
        {
            columns: processedColumns,
            data,
            initialState: {
                ...(defaultPageSize !== undefined && { pageSize: defaultPageSize }),
                ...(defaultPageIndex !== undefined && { pageIndex: defaultPageIndex }),
                ...(defaultSortBy !== undefined && { sortBy: defaultSortBy }),
                ...(defaultSelectedIds !== undefined && { selectedRowIds: defaultSelectedIds }),
            },
            manualPagination: tableOptions.manualPagination ?? disablePagination,
            disableMultiSort,
            disableSortRemove,
            sortTypes,
            filterTypes,
            globalFilter: "globalFilter",
            autoResetResize: false,
            autoResetGlobalFilter: false,
            autoResetPage: false, // do not reset pagination, when data changes
            autoResetSortBy: false,
            ...tableOptions,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useFlexLayout,
        useResizeColumns,
        useRowSelect,
        (hooks) => {
            onSelectionChanged &&
                hooks.visibleColumns.push((visColumns) => [
                    {
                        id: "selection",
                        Header: SelectionHeaderComponent,
                        Cell: SelectionCellComponent,
                        disableSortBy: true,
                        disableGlobalFilter: true,
                        disableResizing: true,
                        width: 48,
                    },
                    ...visColumns,
                ]);
        },
    );

    const {
        pageIndex,
        pageSize,
        sortBy,
        columnResizing: { columnWidths, isResizingColumn },
    } = state;

    // The effects below call certain callbacks from props for certain events. That requires those callbacks to be inside of the dependency
    // array. But then, the effects will also be fired, when that callback reference changes. As an effect does not now, why it is executed,
    // this problem is resolved by passing the previous value also to the callback, so the effect is able to compare old and new value and
    // only call the callback, when there is a difference
    const previousState = usePrevious(state);
    const previousGlobalFilteredRows = usePrevious(globalFilteredRows);
    const previousSelectedFlatRows = usePrevious(selectedFlatRows);
    const previousGlobalFilter = usePrevious(globalFilter);

    useEffect(() => {
        if (onSelectionChanged !== undefined && !isEqual(previousSelectedFlatRows, selectedFlatRows)) {
            onSelectionChanged(selectedFlatRows);
        }
    }, [onSelectionChanged, previousSelectedFlatRows, selectedFlatRows]);

    useEffect(() => {
        if (previousGlobalFilter !== globalFilter) {
            setGlobalFilter(globalFilter);
            gotoPage(0);
        }
    }, [globalFilter, gotoPage, previousGlobalFilter, setGlobalFilter]);

    // make sure to sync table's state with the pageIndex provided via props
    useEffect(() => {
        if (pageIndexProps !== undefined) {
            gotoPage(pageIndexProps);
        }
    }, [pageIndexProps, gotoPage]);

    useEffect(() => {
        // Resizing finished, propagate new sizes upwards, if needed
        onColumnsResized !== undefined &&
            previousState.columnResizing.isResizingColumn != null &&
            isResizingColumn == null &&
            onColumnsResized(columnWidths);
    }, [previousState.columnResizing.isResizingColumn, isResizingColumn, columnWidths, onColumnsResized]);

    useEffect(() => {
        if (previousGlobalFilteredRows !== globalFilteredRows && onOrderChanged !== undefined) {
            const sortedData = globalFilteredRows.map((r) => r.original);
            onOrderChanged(sortedData);
        }
    }, [previousGlobalFilteredRows, globalFilteredRows, onOrderChanged]);

    useEffect(() => {
        sortBy !== previousState.sortBy && onSortByChanged?.(sortBy);
    }, [previousState.sortBy, sortBy, onSortByChanged]);

    useEffect(() => {
        if (previousState.pageIndex === pageIndex && previousState.pageSize === pageSize) {
            return;
        }
        onPaginationChanged?.(pageIndex, pageSize);
    }, [previousState.pageIndex, previousState.pageSize, pageIndex, pageSize, onPaginationChanged]);

    const numOfItems = resolveNumberOfItems(
        data,
        globalFilter !== undefined && globalFilter !== "" ? globalFilteredRows.length : numOfItemsProps,
    );

    const hasFooter = columns.some((column) => column.Footer != null);

    /*
     * isResizingColumn is checked in multiple places to prevent rendering while resizing.
     * This at least makes the handler usable. Remove this when rendering in MeasureTable is improved or component get's replaced.
     */
    return (
        <BaseTableRoot container direction="column" className={className} wrap="nowrap" alignItems="stretch">
            <BaseTableContainer className={fullHeight ? fullHeightClass : undefined}>
                <StyledTable {...getTableProps()}>
                    <BaseTableHead>
                        {headerGroups.map((headerGroup) => (
                            // Linter is not able to figure out that key is provided by getHeaderGroupProps
                            // eslint-disable-next-line react/jsx-key
                            <BaseTableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index, arr) => (
                                    // Linter is not able to figure out that key is provided by getHeaderProps
                                    // eslint-disable-next-line react/jsx-key
                                    <BaseTableHeadCell
                                        {...fixColumnFlexing(column.getHeaderProps(), column, tableOptions.disableResizing)}
                                        className={classNames(column.className, isDense && denseClass)}
                                    >
                                        {column.render("Header")}
                                        {column.canResize && (
                                            <TableResizer {...column.getResizerProps()} isLast={index === arr.length - 1} />
                                        )}
                                    </BaseTableHeadCell>
                                ))}
                            </BaseTableRow>
                        ))}
                    </BaseTableHead>
                    <BaseTableBody {...getTableBodyProps()}>
                        <Suspense fallback={<BaseTableRowSkeletonBars />}>
                            {!isFetching && numOfItems === 0 ? (
                                // Wrap in tr + td to generate valid nested DOM (tbody > tr > td)
                                <tr>
                                    <Typography
                                        component="td"
                                        color="textSecondary"
                                        sx={{
                                            // align similar as table rows
                                            py: 1,
                                            px: 2,
                                        }}
                                    >
                                        {noDataText}
                                    </Typography>
                                </tr>
                            ) : null}
                            {isFetching ? <BaseTableRowSkeletonBars /> : null}
                            {!isResizingColumn
                                ? page.map((row) => {
                                      prepareRow(row);
                                      return (
                                          <BaseTableBodyRow
                                              // needed to satisfy react's key requirement for iterations
                                              key={`${row.getRowProps().key}_${row.getToggleRowSelectedProps().checked}`}
                                              row={row}
                                              rowHover={rowHover}
                                              isRowSelected={isRowSelected?.(row.original)}
                                              disableResizing={tableOptions.disableResizing}
                                          />
                                      );
                                  })
                                : null}
                        </Suspense>
                    </BaseTableBody>
                    {hasFooter && !isResizingColumn ? (
                        <BaseTableFooter>
                            {footerGroups.map((footerGroup) => (
                                // Linter is not able to figure out that key is provided by getFooterGroupProps
                                // eslint-disable-next-line react/jsx-key
                                <BaseTableRow {...footerGroup.getFooterGroupProps()}>
                                    {footerGroup.headers.map((column) => (
                                        // Linter is not able to figure out that key is provided by getFooterProps
                                        // eslint-disable-next-line react/jsx-key
                                        <BaseTableFooterCell
                                            {...fixColumnFlexing(column.getFooterProps(), column, tableOptions.disableResizing)}
                                            className={classNames(column.className, isDense && denseClass)}
                                        >
                                            {column.render("Footer")}
                                        </BaseTableFooterCell>
                                    ))}
                                </BaseTableRow>
                            ))}
                        </BaseTableFooter>
                    ) : null}
                </StyledTable>
            </BaseTableContainer>
            {!disablePagination && (
                <PaginationContainer
                    container
                    justifyContent={paginationHint !== undefined ? "space-between" : "flex-end"}
                    alignItems="center"
                >
                    {paginationHint !== undefined && (
                        <Grid item>
                            <PaginationHint variant="caption" color="textSecondary" noWrap>
                                <InfoOutlinedIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                                <span>{paginationHint}</span>
                            </PaginationHint>
                        </Grid>
                    )}
                    <Grid item>
                        <Pagination
                            onPageChange={gotoPage}
                            onPageSizeChange={setPageSize}
                            pageSizeOptions={pageSizeOptions}
                            page={pageIndex}
                            pageSize={pageSize}
                            data={data}
                            count={numOfItems}
                            itemName={itemName}
                            translate={translate}
                            dense={densePagination}
                        />
                    </Grid>
                </PaginationContainer>
            )}
        </BaseTableRoot>
    );
};

export default BaseTable;

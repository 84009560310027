import { Typography, TypographyProps } from "@mui/material";
import { CellProps } from "react-table";
import Tooltip from "../Tooltip";

export interface ITableTextCellProps<D extends object = Record<string, unknown>>
    extends Partial<Pick<CellProps<D>, "value" | "cell">>,
        Pick<TypographyProps, "align" | "variant" | "className"> {
    title?: string | null;
    children?: any;
}

const TableTextCell = <D extends object = Record<string, unknown>>({
    value,
    title,
    children,
    align,
    variant,
    className,
}: ITableTextCellProps<D>) => {
    const content = value ?? (children as string);
    const fallbackContent = content?.length > 255 ? `${content.substring(0, 252).trim()}...` : content;
    const resolvedTitle = title ?? fallbackContent;
    return content != null ? (
        <Tooltip title={resolvedTitle} onlyOverflowing={title == null}>
            <Typography variant={variant} align={align} noWrap className={className}>
                {content}
            </Typography>
        </Tooltip>
    ) : null;
};

export default TableTextCell;

import { Skeleton, styled, TableRow, TableRowProps } from "@mui/material";

const StyledRow = styled(TableRow)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const TableRowSkeleton = (props: TableRowProps) => {
    return (
        <StyledRow {...props}>
            <Skeleton component="td" width="100%" />
        </StyledRow>
    );
};

export default TableRowSkeleton;

import FirstPageIcon from "@mui/icons-material/FirstPageRounded";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRightRounded";
import LastPageIcon from "@mui/icons-material/LastPageRounded";
import { IconButton, iconButtonClasses, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

const Root = styled("div")(({ theme }) => ({
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
}));

const PageNumber = styled(Typography)(({ theme }) => ({
    minWidth: `${theme.typography.caption.lineHeight ?? 1}em`, // make text span square
}));

const PageNumberButton = styled(IconButton)(({ theme }) => ({
    [`&.${iconButtonClasses.disabled}`]: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const getVisiblePages = (currentPage: number, offset: number, minPage: number, maxPage: number) => {
    // current page might be inconsistent with actual item count (e.g. when actions remove elements and page is still contains the old value)
    const leftPage = Math.min(Math.max(minPage, currentPage - offset), maxPage);
    const rightPage = Math.min(currentPage + offset, maxPage);

    // generate a number array leftPage...rightPage
    const noOfPagesToShow = rightPage - leftPage + 1;
    return [...Array(noOfPagesToShow).keys()].map((i) => leftPage + i);
};

interface IPaginationActionsProps {
    minPage?: number;
    offset?: number; // offset of pages to show in each direction from current page
    // Below are TablePaginationActionsProps, their interface is not exported by mui
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
    page: number;
    count: number;
    rowsPerPage: number;
}

const PaginationActions = ({ minPage = 0, offset = 2, onPageChange, page, count, rowsPerPage }: IPaginationActionsProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const selectPage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => onPageChange(event, newPage);

    const maxPage = count == null || count <= 0 ? 0 : Math.floor((count - 1) / rowsPerPage);
    const pages = getVisiblePages(page, offset, minPage, maxPage);
    const firstPageToDisplay = pages[0];
    const lastPageToDisplay = pages[pages.length - 1];

    const ellipsis = (
        <IconButton disabled aria-label="ellipsis">
            <PageNumber variant="caption" color="inherit">
                …
            </PageNumber>
        </IconButton>
    );

    return (
        <Root>
            <IconButton
                onClick={(e) => selectPage(e, minPage)}
                disabled={page <= minPage}
                aria-label={t(translationKeys.VDLANG_PAGINATION_FIRST_PAGE)}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={(e) => selectPage(e, page - 1)}
                disabled={page <= minPage}
                aria-label={t(translationKeys.VDLANG_PAGINATION_PREVIOUS_PAGE)}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            {firstPageToDisplay > minPage + 1 ? ellipsis : null}
            {pages.map((visiblePage) => (
                <PageNumberButton
                    key={`page${visiblePage}`}
                    onClick={(e) => selectPage(e, visiblePage)}
                    disabled={page === visiblePage}
                    aria-label={`${t(translationKeys.VDLANG_PAGINATION_PAGE)} ${visiblePage}`}
                >
                    <PageNumber variant="caption" color={page !== visiblePage ? "inherit" : undefined}>
                        {visiblePage + 1}
                    </PageNumber>
                </PageNumberButton>
            ))}
            {lastPageToDisplay < maxPage - 1 ? ellipsis : null}
            <IconButton
                onClick={(e) => selectPage(e, page + 1)}
                disabled={page >= maxPage}
                aria-label={t(translationKeys.VDLANG_PAGINATION_NEXT_PAGE)}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={(e) => selectPage(e, maxPage)}
                disabled={page >= maxPage}
                aria-label={t(translationKeys.VDLANG_PAGINATION_LAST_PAGE)}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Root>
    );
};

export default PaginationActions;

import { styled } from "@mui/material";

interface ITableResizerProps {
    isLast: boolean;
}

const TableResizer = styled("div")<ITableResizerProps>(({ isLast, theme }) => ({
    position: "absolute",
    top: 0,
    right: theme.spacing(-1),
    bottom: 0,
    zIndex: 1, // put above neighbor cell
    width: theme.spacing(2),
    ...(isLast && {
        width: theme.spacing(),
        right: 0,
    }),
}));

export default TableResizer;

import BaseTableRow from "./BaseTableRow";
import { TableCell, styled } from "@mui/material";
import React from "react";
import { Row } from "react-table";
import { fixColumnFlexing } from "./BaseTable";

interface IBaseTableBodyRowProps<D extends object = Record<string, unknown>> {
    row: Row<D>;
    rowHover?: boolean;
    isRowSelected?: boolean;
    disableResizing?: boolean;
}

const rowSelectedClass = "VdBaseTableBodyRow-rowSelected";

const StyledTableRow = styled(BaseTableRow)({
    [`&.${rowSelectedClass}`]: {
        opacity: 0.5,
    },
});

const BaseTableBodyCell = styled(TableCell)(({ theme }) => ({
    display: "block", // override default display: table-cell, do not use flex here so children are not flex-items
    overflow: "hidden", // enable ellipsis on table cells
    borderBottom: "none", // border is moved from cell to row, so that vertical centering of cells is possible
    padding: theme.spacing(1, 2),
}));

const BaseTableBodyRow = <D extends object = Record<string, unknown>>({
    row,
    rowHover,
    isRowSelected,
    disableResizing,
}: IBaseTableBodyRowProps<D>) => {
    const { style, ...rowProps } = row.getRowProps();

    return (
        <StyledTableRow
            {...rowProps}
            hover={rowHover}
            style={{ ...style, flexGrow: 0 }}
            className={isRowSelected ? rowSelectedClass : undefined}
        >
            {row.cells.map((cell) => (
                // Linter is not able to figure out that key is provided by getCellProps
                // eslint-disable-next-line react/jsx-key
                <BaseTableBodyCell
                    {...fixColumnFlexing(cell.getCellProps(), cell.column, disableResizing)}
                    className={cell.column.className}
                >
                    {cell.render("Cell")}
                </BaseTableBodyCell>
            ))}
        </StyledTableRow>
    );
};

// React.memo will wipe the generic, so the casting here is needed to be able to still use generics
export default React.memo(BaseTableBodyRow) as typeof BaseTableBodyRow;

import { TableRow, styled } from "@mui/material";

const BaseTableRow = styled(TableRow)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    minHeight: theme.spacing(5),
    borderBottom: `${theme.palette.divider} 1px solid`,
}));

export default BaseTableRow;
